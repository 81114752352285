import React, {useRef} from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Form, Formik } from "formik";
import { useMutation } from "react-query";

import * as PR from "../../prime-modules/index";
import Layout from "../../components/layout/Layout";
import Input from "../../components/input";
import { resetPasswordValidationSchema } from "../../utils/schema";
import { resetPassword } from "../../services/affiliateApi";


const ResetPassword = () => {
    const location = useLocation();
    const toast = useRef();
    const {labels} = useSelector(state=>state.language?.activelang);
    const queryParams = new URLSearchParams(location.search);
    const passwordResetUUID = queryParams.get('id');
    const navigate = useNavigate();
    const mutation = useMutation({
        mutationFn: (values) => resetPassword({ passwordResetUUID, password: values.password }),
        onSuccess: () =>  { toast.current.show({ severity: 'success', summary: 'Success', detail: labels?.LBLN0285 }) ; setTimeout(()=>{navigate('/affiliate-signin')},2000)},
        onError : o =>toast.current.show({ severity: 'error', summary: 'Error', detail:o.message }),
        enabled: false
    });

    const handleSubmit = (values, { resetForm }) => {
        mutation.mutate(values);
        resetForm();
    };

    return (
      <>
       <PR.Toast ref={toast} />
        <Layout>
          <section className="signup-section">
            <div className="grid">
              <div className="col-12 text-center">
                <h1>{labels?.LBLN0043}</h1>
                <p>
                {labels?.LBLN0040}
                </p>
                <div className="signup-wrapper">
                  {/* Form section starts here */}
                  <Formik
                    initialValues={{
                      password: "",
                      confirmPassword: ""
                    }}
                    validationSchema={resetPasswordValidationSchema}
                    onSubmit={handleSubmit}
                  >
                    <Form autoComplete="off" className="form-block">
                      <div className="grid">
                        <div className="col-12 md:col-12">
                          <div className="flex flex-column gap-2 input-field">
                            <Input id={'password'} name={'password'} feedback={false} type={'password'}  label={labels?.LBLN0071} aria-describedby={'password'} toggleMask placeholder={labels?.LBLN0228} />    
                          </div>
                        </div>
                        <div className="col-12 md:col-12">
                          <div className="flex flex-column gap-2 input-field">
                            <Input id={'confirmPassword'} name={'confirmPassword'} feedback={false} type={'password'} label={labels?.LBLN0005} aria-describedby={'confirm-password'} toggleMask placeholder={labels?.LBLN0229} />    
                          </div>
                        </div>
                        <div className="col-12 mb-4 pb-0">
                          <PR.Button
                            label={labels?.LBLN0043}
                            type="submit"
                            className="signup-button w-12"
                          />
                        </div>
                      </div>
                      <p className="link-p">
                      {labels?.LBLN0070} <Link to="/signup">{labels?.LBLN0226}</Link>
                      </p>
                    </Form>
                  </Formik>
                  {/* Form section ends here */}
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </>
    );
};

export default ResetPassword;