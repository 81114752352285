import React, { useRef, useState } from "react";
import * as PR from "../../prime-modules/index";
import "./index.scss";
import { useQuery } from "react-query";
import { afDashboard } from "../../services/affiliateApi";
import { modifyDate, setPrice } from "../../utils/reuse";
import data2goicon from "../../assets/images/data2go-icon.png"
import moment from "moment";
import { cloneDeep, isEqual } from 'lodash';
import { globalConfig } from "../../utils/constant";
import { handleExportCSV } from "./helpers";
import Layout from "../../components/layout/Layout";
import { useSelector } from "react-redux";
import { QRCode } from 'react-qrcode-logo';

const defaultFilter = { listingType: 'list', offset: 0, limit: globalConfig.pageCount + 1, sortField: 'createdTs', sortOrder: 'desc' };

const AffiliatesDashboard = () => {

    const qrRef = useRef({});
    const { affiliate } = useSelector(state => state.language?.activelang);
    const { afId } = useSelector(state => state.afAuth?.afUserData);
    const affTarget = process.env.REACT_APP_AFF_URL;
    const affiliateLink = `${affTarget}&referrer=afid=${afId}`;

    const [dates, setDates] = useState(null);
    const params = useRef({ ...defaultFilter });
    const [page, setPage] = useState(1)
    const toast = useRef(null);
    //const [selectedStatus, setSelectedStatus] = useState(null);
    const searchedParams = useRef({ ...defaultFilter });

    const { data, refetch, isFetching } = useQuery(
        ['AFFILIATE_DASHBOARD'],
        () => afDashboard(`orders?listingType=${params.current.listingType ?? ''}&offset=${params.current.offset ?? ''}&limit=${params.current.limit ?? ''}&orderBy=${params.current.sortField ?? ''}&direction=${params.current.sortOrder ?? ''}&orderStatus=COMPLETED&purchaseDateFrom=${params.current.purchaseDateFrom ?? ''}&purchaseDateTo=${params.current.purchaseDateTo !== "" ? params.current.purchaseDateTo ?? "" : params.current.purchaseDateFrom ?? ""}`),
        {
            staleTime: 999999999,
            onSuccess: o => {
                if (params.current.listingType === 'export') {
                    params.current.listingType = 'list';
                    if (o !== null) {
                        const ex = cloneDeep(o);
                        ex?.forEach(el => {
                            el.createdTs = modifyDate(el.createdTs)
                            el.totalOrderAmount = setPrice(el.totalOrderAmount ?? '')
                        });
                        handleExportCSV(ex);
                    }
                }
                searchedParams.current = { ...params.current }
            },
            onError: o => { toast.current.show({ severity: 'error', summary: 'Error', detail: o?.message }) }
        }
    );

    const dateChangeHandler = ({ value }) => {
        if (value?.length) {
            const [from, to] = value;
            params.current.purchaseDateFrom = from ? moment(from).format('MM-DD-YYYY') : '';
            params.current.purchaseDateTo = to ? moment(to).format('MM-DD-YYYY') : '';
            setDates([from, to]);
        } else {
            params.current.purchaseDateFrom = "";
            params.current.purchaseDateTo = "";
            setDates(null);
        }
    };

    // const orderStatusChangeHandler = ({value}) =>{
    //     params.current.orderStatus = value.join(',').trim();
    //     setSelectedStatus(value);
    // }

    const resetClickHandler = () => {
        setDates(null)
        //setSelectedStatus(null)
        setPage(1)
        searchedParams.current = { ...defaultFilter }
        params.current = { ...defaultFilter };
        refetch();
    }

    const paginationClickHandler = v => {
        params.current.offset = ((page + v) - 1) * globalConfig.pageCount;
        setPage(p => p + v);
        refetch();
    }

    const sortClickHandler = k => {
        params.current.sortField = k;
        params.current.offset = 0;
        setPage(1);
        params.current.sortOrder = params.current.sortOrder === 'asc' ? 'desc' : 'asc';
        refetch()
    }

    const downloadCsvClickHandler = () => {
        params.current.listingType = 'export';
        refetch();
    }
    // Order status is not being handled by the backend.
    // const status = [
    //     { name: affiliate?.AFFN0060, value: 'CANCELLED' },
    //     { name: affiliate?.AFFN0052, value: 'COMPLETED' },
    //     { name: affiliate?.AFFN0054, value: 'PAYMENT_FAILED' },
    //     { name: affiliate?.AFFN0053, value: 'PENDING' },
    //     { name: affiliate?.AFFN0059, value: 'REFUNDED' },
    // ];

    const header = (
        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
            <h2>{affiliate?.AFFN0046}</h2>
            <div className="filter-block flex flex-wrap align-items-center gap-3">
                <PR.Button onClick={downloadCsvClickHandler} label={affiliate?.AFFN0047} rounded className="filter-buttons" />
                <PR.Calendar value={dates} dateFormat="dd-mm-yy" onChange={dateChangeHandler} showButtonBar={isEqual(searchedParams.current, defaultFilter)} selectionMode="range" readOnlyInput hideOnRangeSelection placeholder={affiliate?.AFFN0048} showIcon iconPos="left" className="purchased-date" />
                {/* <PR.MultiSelect value={selectedStatus} onChange={orderStatusChangeHandler} options={status} optionLabel="name" placeholder={affiliate?.AFFN0049} className="status-dropdown" /> */}
                <PR.Button disabled={isEqual(searchedParams.current, params.current) || !params.current.purchaseDateTo && !params.current.purchaseDateFrom || isEqual(params.current, defaultFilter) || isFetching} onClick={refetch} label={affiliate?.AFFN0050} rounded className="filter-buttons" />
                <PR.Button disabled={isEqual(searchedParams.current, defaultFilter)} onClick={resetClickHandler} label={affiliate?.AFFN0051} rounded outlined className="filter-buttons reset-button" />
            </div>
        </div>
    );
    const tableFooter = <div className={isFetching ? "pagination-section btn-disable" : 'pagination-section'}>
        <PR.Button icon="pi pi-chevron-left" onClick={paginationClickHandler.bind(null, -1)} disabled={page <= 1} />
        <p>{page}</p>
        <PR.Button icon="pi pi-chevron-right" onClick={paginationClickHandler.bind(null, 1)} disabled={data?.length <= (globalConfig.pageCount)} />
    </div>

    const orderStatus = ({ orderStatus }) => <span className={orderStatus === 'COMPLETED' ? 'completed' : orderStatus === 'PENDING' ? "pending" : 'failed'}>
        {orderStatus}
    </span>

    const sortSymbol = k => params.current.sortField === k ? `-amount-${params.current.sortOrder === 'asc' ? 'up' : 'down'}` : '';

    const copyToClipboard = (affiliateLink) => {
        navigator.clipboard.writeText(affiliateLink);
        toast.current.show({
            severity: 'success',
            summary: 'Copied',
            detail: `Affiliate Link copied to clipboard!`,
            life: 3000,
        });
    };

    const downloadQRCode = async (id) => {
        const canvas = qrRef.current[id].querySelector('canvas');
        const image = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = image;
        link.download = 'affiliate_qrcode.png';
        link.click();
    };

    return (
        <>
            <PR.Toast ref={toast} />
            <Layout>
                <section className="affiliates-dashboard">
                    <div className="grid">
                        <div className="col-12 text-center">
                            <h1>{affiliate?.AFFN0044}</h1>
                            <p>{affiliate?.AFFN0045}</p>
                            <div className="affiliate-link-container">
                                <span className="affiliate-link-label">Affiliate Link</span>
                                <div className="link-block">
                                    <span className="affiliate-link">{affiliateLink}</span>
                                    <div className="right">
                                        <span className="copy">
                                            <i title="Copy Affiliate Link"
                                                className="pi pi-copy"
                                                onClick={() => copyToClipboard(affiliateLink)}
                                            />
                                        </span>
                                        {<>
                                        <div className="copy" ref={(el) => qrRef.current[affiliateLink] = el} style={{display: 'none'}}>
                                            <QRCode
                                                id={affiliateLink}
                                                size={500}
                                                logoImage={data2goicon}
                                                logoWidth={50}
                                                logoHeight={50}
                                                value={affiliateLink} 
                                            />
                                        </div>
                                        <i
                                            className="pi pi-download"
                                            title="Download QR Code"
                                            onClick={() => downloadQRCode(affiliateLink)} 
                                        />
                                        </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="affiliates-datatable-wrapper">
                                <PR.DataTable sortField="" footer={!!data ? tableFooter : null} value={data ?? []} header={header} className="sales-datatable" emptyMessage="No data available.">
                                    <PR.Column
                                        field="dataPlan"
                                        style={{ width: "340px" }}
                                        body={isFetching && <PR.Skeleton width="300px" height="22px" ></PR.Skeleton>}
                                        header={<div onClick={sortClickHandler.bind(null, 'dataPlan')}>{affiliate?.AFFN0055} <i className={`pi pi-sort${sortSymbol('dataPlan')}-alt`}></i> </div>}
                                    />
                                    <PR.Column
                                        field="createdTs"
                                        style={{ width: "260px" }}
                                        header={<div onClick={sortClickHandler.bind(null, 'createdTs')}>{affiliate?.AFFN0056} <i className={`pi pi-sort${sortSymbol('createdTs')}-alt`}></i></div>}
                                        body={isFetching ? <PR.Skeleton width="220px" height="22px" ></PR.Skeleton> : d => <>{modifyDate(d.createdTs)}</>}
                                    />
                                    <PR.Column
                                        field="totalOrderAmount"
                                        style={{ width: "120px" }}
                                        header={<div onClick={sortClickHandler.bind(null, 'totalOrderAmount')}>{affiliate?.AFFN0057} <i className={`pi pi-sort${sortSymbol('totalOrderAmount')}-alt`}></i></div>}
                                        body={isFetching ? <PR.Skeleton width="90px" height="22px" ></PR.Skeleton> : d => <>{setPrice(d.totalOrderAmount)}</>}
                                    />
                                    <PR.Column
                                        field="orderStatus"
                                        style={{ width: "190px" }}
                                        header={<div onClick={sortClickHandler.bind(null, 'orderStatus')}>{affiliate?.AFFN0058} <i className={`pi pi-sort${sortSymbol('orderStatus')}-alt`}></i></div>}
                                        body={isFetching ? <PR.Skeleton width="150px" height="22px" ></PR.Skeleton> : orderStatus}
                                    />
                                </PR.DataTable>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default AffiliatesDashboard;