import React, { useState, useRef, useEffect } from "react";
import * as PR from "../../prime-modules/index";
import { logo, user, logout, logoIcon } from "../../assets/images";
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { afLogoutReducer } from "../../store/afAuth.slice";
import { useQuery } from "react-query";
import { languageGetApi } from "../../services/affiliateApi";
import { setLanguageIntial } from "../../store/language.slice";
import { useTranslation } from "react-i18next";

const HeaderMenu = () => {

    const navigate = useNavigate();
    const userMenu = useRef(null);
    const { i18n } = useTranslation();
    const language = i18n.language?.split('-')[0];
    const pathname = window.location.pathname?.toLowerCase();
    const storeLanguage = useSelector(state => state.language);
    const { labels } = storeLanguage?.activelang
    const { afIsAuthenticated } = useSelector(state => state.afAuth)
    const dispatch = useDispatch();

    const [visible, setVisible] = useState(false);

    const languages = [
        { name: 'English', code: 'EN' },
        { name: 'Spanish', code: 'ES' },
        { name: 'Portuguese', code: 'PT' }
    ];

    const userMenuList = [
        { label: labels?.LBLN0171, icon: <PR.Image src={logout} />, command:() =>{ setVisible(true) } }
    ];

    const changeLanguage = e => {
        const ln = e.value.code.toLocaleLowerCase();
        i18n.changeLanguage(ln);
    };

    const logoutHandler = () => {
        dispatch(afLogoutReducer()); setVisible(false);
    }

    const onSuccess = v => {dispatch(setLanguageIntial({k:language,v}))};
    const enabled = true
    useQuery(['LABELS',language],languageGetApi.bind(null,language,"labels-new"),{
        enabled,
       onSuccess
    });

     useQuery(['AFFILIATE',language],languageGetApi.bind(null,language,"affiliate-new"),{
        enabled,
       onSuccess
    });

    useQuery(['VALIDATIONS',language],languageGetApi.bind(null,language,"validations-new"),{
        enabled,
       onSuccess
    }); 

    const start = (
        <div className="header-left">
            <Link to={afIsAuthenticated ? '/' : process.env.REACT_APP_PARTNER_URL} target={!afIsAuthenticated && '_blank' }><PR.Image src={logo} alt="data2go logo" className="logo-img hide-on-mobile" /></Link>
            <Link to="/"><PR.Image src={logoIcon} alt="data2go logo" className="logo-img show-on-mobile" /></Link>
        </div>
    );

    const end = (
        <div className="header-right flex align-items-center gap-3">
            <div className="card">
                <PR.Dropdown
                    value={languages.filter(el=>el.code.toLocaleLowerCase()===language)?.[0]}
                    onChange={changeLanguage}
                    options={languages}
                    optionLabel="name"
                    placeholder="English"
                    className="language-dropdown"
                    panelClassName="language-panel"
                />
            </div>

            {afIsAuthenticated
                ? <div className="card flex justify-content-center">
                    <PR.Menu model={userMenuList} popup ref={userMenu} />
                    <div className="flex align-items-center" onClick={(e) => userMenu.current.toggle(e)}>
                        <PR.Button className="my-account-buttons user-button">
                            <PR.Image src={user} alt="Logged-in User" />
                        </PR.Button>
                        <i className="pi pi-chevron-down"></i>
                    </div>
                </div>
                :  pathname === "/" ?   <PR.Button onClick={navigate.bind(null, '/affiliate-signup')} label={labels?.LBLN0038} className="login-button" /> : <PR.Button onClick={navigate.bind(null, '/')} label={labels?.LBLN0068} className="login-button" />
            }
        </div>
    );

    return (
        <>
            <section className="header-menu-section">
                <div className="grid grid-nogutter">
                    <div className="col-12">
                        <PR.Menubar  start={start} end={end} />
                    </div>
                </div>
            </section>
            <PR.Dialog visible={visible} onHide={() => { if (!visible) return; setVisible(false); }} className="logout-dialog">
                <div className="flex align-items-center gap-3">
                    <div className="logout-icon"><PR.Image src={logout} /></div>
                    <h2>{labels?.LBLN0171}</h2>
                </div>
                <div className="content">
                    <p>{labels?.LBLN0174}</p>
                    <div className="flex align-items-center gap-3">
                        <PR.Button label={labels?.LBLN0175} className="logout-buttons cancel" onClick={() => setVisible(false)} />
                        <PR.Button label={labels?.LBLN0171} className="logout-buttons" onClick={logoutHandler} />
                    </div>
                </div>
            </PR.Dialog>
        </>
    );
};

export default HeaderMenu;
