import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { Form, Formik } from "formik";
import { useQuery } from "react-query";

import * as PR from "../../prime-modules/index";
import Layout from "../../components/layout/Layout";
import Input from "../../components/input";
import { forgotPasswordValidationSchema } from "../../utils/schema";
import { forgotPassword } from "../../services/affiliateApi";

const ForgotPassword = () => {
    const toast = useRef();
    const {labels} = useSelector(state=>state.language?.activelang);
    const emailRef = useRef(null)

    const {  isLoading: bundleListLoading, error: emailError, refetch } = useQuery({
        queryFn: () => forgotPassword(emailRef.current),
        onSuccess: () => toast.current.show({ severity: 'success', summary: 'Success', detail: labels?.LBLN0287 }),
        enabled: false
    });

    const handleSubmit = (value, { resetForm }) => {
     emailRef.current = value?.email
     refetch()
     resetForm();
    };

    return (
        <Layout>
           <PR.Toast ref={toast} />
          <section className="signup-section">
            <div className="grid">
              <div className="col-12 text-center">
                <h1>{labels?.LBLN0033}</h1>
                <p>
                  {labels?.LBLN0224}
                </p>
                <div className="signup-wrapper">
                  {/* Form section starts here */}
                  <Formik
                    initialValues={{
                      email: "",
                    }}
                    validationSchema={forgotPasswordValidationSchema}
                    onSubmit={handleSubmit}
                  >
                    <Form autoComplete="off" className="form-block">
                      <div className="grid">
                        <div className="col-12 md:col-12">
                            <Input
                              label={labels?.LBLN0037}
                              name={"email"}
                              type={"email"}
                              aria-describedby={"email"}
                              placeholder={labels?.LBLN0225}
                            />
                        </div>
                        <div className="col-12 mb-4 pb-0">
                          {emailError && (
                            <span className="err-msg">
                              {labels[emailError.message]}
                            </span>
                          )}
                          <PR.Button
                            label={labels?.LBLN0227}
                            type="submit"
                            disabled={bundleListLoading}
                            className="signup-button w-12"
                          />
                        </div>
                      </div>
                      <p className="link-p">
                        {labels?.LBLN0070} <Link to="/affiliate-signup">{labels?.LBLN0226}</Link>
                      </p>
                    </Form>
                  </Formik>
                  {/* Form section ends here */}
                </div>
              </div>
            </div>
          </section>
        </Layout>
    );
};

export default ForgotPassword;